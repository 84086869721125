module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["AW-11082494271","G-NG805TM1ED"],"gtagConfig":{"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":true,"exclude":[]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Measurable AI","short_name":"MAI","start_url":"/","background_color":"#221852","theme_color":"#221852","display":"standalone","gcm_sender_id":"539876079581","icon":"src/images/manifest-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/codebuild/output/src2794519347/src/mai-website/src/intl","languages":["en-US"],"defaultLanguage":"en-US","redirect":true,"redirectComponent":"/codebuild/output/src2794519347/src/mai-website/src/components/seo.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
